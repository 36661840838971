exports.components = {
  "component---src-components-layouts-event-layout-tsx-content-file-path-content-engage-events-01-27-2025-building-better-ux-in-science-mdx": () => import("./../../../src/components/layouts/EventLayout.tsx?__contentFilePath=/home/runner/work/strudel-web/strudel-web/content/engage/events/01-27-2025-building-better-ux-in-science.mdx" /* webpackChunkName: "component---src-components-layouts-event-layout-tsx-content-file-path-content-engage-events-01-27-2025-building-better-ux-in-science-mdx" */),
  "component---src-components-layouts-event-layout-tsx-content-file-path-content-engage-events-02-21-2024-intro-to-ux-mdx": () => import("./../../../src/components/layouts/EventLayout.tsx?__contentFilePath=/home/runner/work/strudel-web/strudel-web/content/engage/events/02-21-2024-intro-to-ux.mdx" /* webpackChunkName: "component---src-components-layouts-event-layout-tsx-content-file-path-content-engage-events-02-21-2024-intro-to-ux-mdx" */),
  "component---src-components-layouts-event-layout-tsx-content-file-path-content-engage-events-03-08-2024-getting-started-with-strudel-ds-mdx": () => import("./../../../src/components/layouts/EventLayout.tsx?__contentFilePath=/home/runner/work/strudel-web/strudel-web/content/engage/events/03-08-2024-getting-started-with-strudel-ds.mdx" /* webpackChunkName: "component---src-components-layouts-event-layout-tsx-content-file-path-content-engage-events-03-08-2024-getting-started-with-strudel-ds-mdx" */),
  "component---src-components-layouts-event-layout-tsx-content-file-path-content-engage-events-03-19-2024-strudel-ds-hackathon-mdx": () => import("./../../../src/components/layouts/EventLayout.tsx?__contentFilePath=/home/runner/work/strudel-web/strudel-web/content/engage/events/03-19-2024-strudel-ds-hackathon.mdx" /* webpackChunkName: "component---src-components-layouts-event-layout-tsx-content-file-path-content-engage-events-03-19-2024-strudel-ds-hackathon-mdx" */),
  "component---src-components-layouts-home-layout-tsx-content-file-path-content-home-mdx": () => import("./../../../src/components/layouts/HomeLayout.tsx?__contentFilePath=/home/runner/work/strudel-web/strudel-web/content/home.mdx" /* webpackChunkName: "component---src-components-layouts-home-layout-tsx-content-file-path-content-home-mdx" */),
  "component---src-components-layouts-news-layout-tsx-content-file-path-content-engage-news-01-27-2025-building-better-ux-in-science-mdx": () => import("./../../../src/components/layouts/NewsLayout.tsx?__contentFilePath=/home/runner/work/strudel-web/strudel-web/content/engage/news/01-27-2025-building-better-ux-in-science.mdx" /* webpackChunkName: "component---src-components-layouts-news-layout-tsx-content-file-path-content-engage-news-01-27-2025-building-better-ux-in-science-mdx" */),
  "component---src-components-layouts-news-layout-tsx-content-file-path-content-engage-news-02-05-2024-strudel-event-series-mdx": () => import("./../../../src/components/layouts/NewsLayout.tsx?__contentFilePath=/home/runner/work/strudel-web/strudel-web/content/engage/news/02-05-2024-strudel-event-series.mdx" /* webpackChunkName: "component---src-components-layouts-news-layout-tsx-content-file-path-content-engage-news-02-05-2024-strudel-event-series-mdx" */),
  "component---src-components-layouts-news-layout-tsx-content-file-path-content-engage-news-02-21-2024-journey-to-strudel-mdx": () => import("./../../../src/components/layouts/NewsLayout.tsx?__contentFilePath=/home/runner/work/strudel-web/strudel-web/content/engage/news/02-21-2024-journey-to-strudel.mdx" /* webpackChunkName: "component---src-components-layouts-news-layout-tsx-content-file-path-content-engage-news-02-21-2024-journey-to-strudel-mdx" */),
  "component---src-components-layouts-news-layout-tsx-content-file-path-content-engage-news-03-08-2024-getting-started-with-strudel-webinar-mdx": () => import("./../../../src/components/layouts/NewsLayout.tsx?__contentFilePath=/home/runner/work/strudel-web/strudel-web/content/engage/news/03-08-2024-getting-started-with-strudel-webinar.mdx" /* webpackChunkName: "component---src-components-layouts-news-layout-tsx-content-file-path-content-engage-news-03-08-2024-getting-started-with-strudel-webinar-mdx" */),
  "component---src-components-layouts-news-layout-tsx-content-file-path-content-engage-news-03-19-2024-strudel-hackathon-mdx": () => import("./../../../src/components/layouts/NewsLayout.tsx?__contentFilePath=/home/runner/work/strudel-web/strudel-web/content/engage/news/03-19-2024-strudel-hackathon.mdx" /* webpackChunkName: "component---src-components-layouts-news-layout-tsx-content-file-path-content-engage-news-03-19-2024-strudel-hackathon-mdx" */),
  "component---src-components-layouts-news-layout-tsx-content-file-path-content-engage-news-11-12-2024-strudel-is-growing-mdx": () => import("./../../../src/components/layouts/NewsLayout.tsx?__contentFilePath=/home/runner/work/strudel-web/strudel-web/content/engage/news/11-12-2024-strudel-is-growing.mdx" /* webpackChunkName: "component---src-components-layouts-news-layout-tsx-content-file-path-content-engage-news-11-12-2024-strudel-is-growing-mdx" */),
  "component---src-components-layouts-page-layout-tsx-content-file-path-content-about-background-mdx": () => import("./../../../src/components/layouts/PageLayout.tsx?__contentFilePath=/home/runner/work/strudel-web/strudel-web/content/about/background.mdx" /* webpackChunkName: "component---src-components-layouts-page-layout-tsx-content-file-path-content-about-background-mdx" */),
  "component---src-components-layouts-page-layout-tsx-content-file-path-content-about-glossary-mdx": () => import("./../../../src/components/layouts/PageLayout.tsx?__contentFilePath=/home/runner/work/strudel-web/strudel-web/content/about/glossary.mdx" /* webpackChunkName: "component---src-components-layouts-page-layout-tsx-content-file-path-content-about-glossary-mdx" */),
  "component---src-components-layouts-page-layout-tsx-content-file-path-content-about-team-mdx": () => import("./../../../src/components/layouts/PageLayout.tsx?__contentFilePath=/home/runner/work/strudel-web/strudel-web/content/about/team.mdx" /* webpackChunkName: "component---src-components-layouts-page-layout-tsx-content-file-path-content-about-team-mdx" */),
  "component---src-components-layouts-page-layout-tsx-content-file-path-content-design-system-code-library-mdx": () => import("./../../../src/components/layouts/PageLayout.tsx?__contentFilePath=/home/runner/work/strudel-web/strudel-web/content/design-system/code-library.mdx" /* webpackChunkName: "component---src-components-layouts-page-layout-tsx-content-file-path-content-design-system-code-library-mdx" */),
  "component---src-components-layouts-page-layout-tsx-content-file-path-content-design-system-overview-mdx": () => import("./../../../src/components/layouts/PageLayout.tsx?__contentFilePath=/home/runner/work/strudel-web/strudel-web/content/design-system/overview.mdx" /* webpackChunkName: "component---src-components-layouts-page-layout-tsx-content-file-path-content-design-system-overview-mdx" */),
  "component---src-components-layouts-page-layout-tsx-content-file-path-content-design-system-release-status-mdx": () => import("./../../../src/components/layouts/PageLayout.tsx?__contentFilePath=/home/runner/work/strudel-web/strudel-web/content/design-system/release-status.mdx" /* webpackChunkName: "component---src-components-layouts-page-layout-tsx-content-file-path-content-design-system-release-status-mdx" */),
  "component---src-components-layouts-page-layout-tsx-content-file-path-content-design-system-task-flows-overview-mdx": () => import("./../../../src/components/layouts/PageLayout.tsx?__contentFilePath=/home/runner/work/strudel-web/strudel-web/content/design-system/task-flows/overview.mdx" /* webpackChunkName: "component---src-components-layouts-page-layout-tsx-content-file-path-content-design-system-task-flows-overview-mdx" */),
  "component---src-components-layouts-page-layout-tsx-content-file-path-content-design-system-tutorials-mdx": () => import("./../../../src/components/layouts/PageLayout.tsx?__contentFilePath=/home/runner/work/strudel-web/strudel-web/content/design-system/tutorials.mdx" /* webpackChunkName: "component---src-components-layouts-page-layout-tsx-content-file-path-content-design-system-tutorials-mdx" */),
  "component---src-components-layouts-page-layout-tsx-content-file-path-content-engage-contribute-mdx": () => import("./../../../src/components/layouts/PageLayout.tsx?__contentFilePath=/home/runner/work/strudel-web/strudel-web/content/engage/contribute.mdx" /* webpackChunkName: "component---src-components-layouts-page-layout-tsx-content-file-path-content-engage-contribute-mdx" */),
  "component---src-components-layouts-page-layout-tsx-content-file-path-content-planning-framework-overview-mdx": () => import("./../../../src/components/layouts/PageLayout.tsx?__contentFilePath=/home/runner/work/strudel-web/strudel-web/content/planning-framework/overview.mdx" /* webpackChunkName: "component---src-components-layouts-page-layout-tsx-content-file-path-content-planning-framework-overview-mdx" */),
  "component---src-components-layouts-page-layout-tsx-content-file-path-content-planning-framework-typology-mdx": () => import("./../../../src/components/layouts/PageLayout.tsx?__contentFilePath=/home/runner/work/strudel-web/strudel-web/content/planning-framework/typology.mdx" /* webpackChunkName: "component---src-components-layouts-page-layout-tsx-content-file-path-content-planning-framework-typology-mdx" */),
  "component---src-components-layouts-task-flow-layout-tsx-content-file-path-content-design-system-task-flows-compare-data-mdx": () => import("./../../../src/components/layouts/TaskFlowLayout.tsx?__contentFilePath=/home/runner/work/strudel-web/strudel-web/content/design-system/task-flows/compare-data.mdx" /* webpackChunkName: "component---src-components-layouts-task-flow-layout-tsx-content-file-path-content-design-system-task-flows-compare-data-mdx" */),
  "component---src-components-layouts-task-flow-layout-tsx-content-file-path-content-design-system-task-flows-contribute-data-mdx": () => import("./../../../src/components/layouts/TaskFlowLayout.tsx?__contentFilePath=/home/runner/work/strudel-web/strudel-web/content/design-system/task-flows/contribute-data.mdx" /* webpackChunkName: "component---src-components-layouts-task-flow-layout-tsx-content-file-path-content-design-system-task-flows-contribute-data-mdx" */),
  "component---src-components-layouts-task-flow-layout-tsx-content-file-path-content-design-system-task-flows-explore-data-mdx": () => import("./../../../src/components/layouts/TaskFlowLayout.tsx?__contentFilePath=/home/runner/work/strudel-web/strudel-web/content/design-system/task-flows/explore-data.mdx" /* webpackChunkName: "component---src-components-layouts-task-flow-layout-tsx-content-file-path-content-design-system-task-flows-explore-data-mdx" */),
  "component---src-components-layouts-task-flow-layout-tsx-content-file-path-content-design-system-task-flows-manage-account-mdx": () => import("./../../../src/components/layouts/TaskFlowLayout.tsx?__contentFilePath=/home/runner/work/strudel-web/strudel-web/content/design-system/task-flows/manage-account.mdx" /* webpackChunkName: "component---src-components-layouts-task-flow-layout-tsx-content-file-path-content-design-system-task-flows-manage-account-mdx" */),
  "component---src-components-layouts-task-flow-layout-tsx-content-file-path-content-design-system-task-flows-monitor-activities-mdx": () => import("./../../../src/components/layouts/TaskFlowLayout.tsx?__contentFilePath=/home/runner/work/strudel-web/strudel-web/content/design-system/task-flows/monitor-activities.mdx" /* webpackChunkName: "component---src-components-layouts-task-flow-layout-tsx-content-file-path-content-design-system-task-flows-monitor-activities-mdx" */),
  "component---src-components-layouts-task-flow-layout-tsx-content-file-path-content-design-system-task-flows-run-computation-mdx": () => import("./../../../src/components/layouts/TaskFlowLayout.tsx?__contentFilePath=/home/runner/work/strudel-web/strudel-web/content/design-system/task-flows/run-computation.mdx" /* webpackChunkName: "component---src-components-layouts-task-flow-layout-tsx-content-file-path-content-design-system-task-flows-run-computation-mdx" */),
  "component---src-components-layouts-task-flow-layout-tsx-content-file-path-content-design-system-task-flows-run-interactive-computation-mdx": () => import("./../../../src/components/layouts/TaskFlowLayout.tsx?__contentFilePath=/home/runner/work/strudel-web/strudel-web/content/design-system/task-flows/run-interactive-computation.mdx" /* webpackChunkName: "component---src-components-layouts-task-flow-layout-tsx-content-file-path-content-design-system-task-flows-run-interactive-computation-mdx" */),
  "component---src-components-layouts-task-flow-layout-tsx-content-file-path-content-design-system-task-flows-search-data-repositories-mdx": () => import("./../../../src/components/layouts/TaskFlowLayout.tsx?__contentFilePath=/home/runner/work/strudel-web/strudel-web/content/design-system/task-flows/search-data-repositories.mdx" /* webpackChunkName: "component---src-components-layouts-task-flow-layout-tsx-content-file-path-content-design-system-task-flows-search-data-repositories-mdx" */),
  "component---src-components-layouts-task-flow-layout-tsx-content-file-path-content-design-system-task-flows-track-state-mdx": () => import("./../../../src/components/layouts/TaskFlowLayout.tsx?__contentFilePath=/home/runner/work/strudel-web/strudel-web/content/design-system/task-flows/track-state.mdx" /* webpackChunkName: "component---src-components-layouts-task-flow-layout-tsx-content-file-path-content-design-system-task-flows-track-state-mdx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-engage-blog-tsx": () => import("./../../../src/pages/engage/blog.tsx" /* webpackChunkName: "component---src-pages-engage-blog-tsx" */),
  "component---src-pages-engage-events-index-tsx": () => import("./../../../src/pages/engage/events/index.tsx" /* webpackChunkName: "component---src-pages-engage-events-index-tsx" */),
  "component---src-pages-engage-index-tsx": () => import("./../../../src/pages/engage/index.tsx" /* webpackChunkName: "component---src-pages-engage-index-tsx" */),
  "component---src-pages-engage-news-index-tsx": () => import("./../../../src/pages/engage/news/index.tsx" /* webpackChunkName: "component---src-pages-engage-news-index-tsx" */)
}

